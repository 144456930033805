import { gsap } from "gsap";
import { ScrollTrigger, ScrollToPlugin } from "gsap/all";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const heroTL = gsap.timeline();
const header = document.querySelector("header");

if (header) {
   // Hero Heading
   const heroHeading = document.querySelector("#hero-heading");
   if (heroHeading) {
      const headingParts = heroHeading.innerText.split("\n");
      heroHeading.innerHTML = ""
      console.log(headingParts)
      
      headingParts.forEach(part => {
         if (part.length > 0) {
            const span = document.createElement("span");
            span.innerText = part.replace("<br>", "").trim();
            heroHeading.appendChild(span)
         }
      });
      
      // Heading Animation
      heroTL.from("#hero-heading span", {
         yPercent: 75,
         opacity: 0,
         stagger: 0.25,
         duration: 0.25,
         ease: "expo"
      })
   }
   
   // Hero Background
   heroTL.fromTo("#hero-bg", {
      width: "100%",
      yPercent: -25,
      y: 0,
      x: "-50%",
      borderRadius: 0
   }, {
      width: "calc(100% - 32px)",
      yPercent: 0,
      y: "1rem",
      x: "-50%",
      borderRadius: "1rem",
      ease: "slow",
      duration: 1.5
   })
   
   // Hero Sub-Heading
   heroTL.fromTo("#hero-text", {
      y: "60vh",
   }, {
      y: 0,
      ease: "expo",
      duration: 1.5
   }, "<")
   
   // Hero CTA
   heroTL.fromTo("#hero-cta", {
      y: "60vh",
   }, {
      y: 0,
      ease: "expo",
      duration: 1.875,
   }, "<")
   
   // Pattern
   heroTL.from("#hero-pattern-left", {
      y: "60vh",
      x: "-50vw",
      ease: "slow",
      duration: 0.75,
   })
   heroTL.from("#hero-pattern-right", {
      y: "60vh",
      x: "50vw",
      ease: "slow",
      duration: 0.75
   }, "<")
   heroTL.from("#hero-pattern-bottom", {
      y: "100vh",
      x: -100,
      ease: "slow",
      duration: 0.75,
      onComplete: () => {
         if (header) {
            header.classList.remove("xl:overflow-hidden")
         }
      }
   })
   
}

// Navbar
const nav = document.querySelector('#nav')
if (nav) {
   heroTL.from(nav, {
      yPercent: -150,
      ease: "slow",
      duration: 0.5,
   }, "<")
}

// Inner Background
const backgroundAnimation = () => {
   let windowWidth = window.innerWidth;
   let outer = 1792;
   let calcBgWidth = "calc(100% - 2rem)";
   let calcBgWidthLg;
   let borderRadius = "1rem";
   let borderRadiusLg = "3rem";
   
   // Adjust on Viewport
   const adjustWidth = () => {
      let maxWidth = windowWidth > outer ? outer : windowWidth;
      if (window.matchMedia('(max-width: 767px)').matches) {
         calcBgWidthLg = maxWidth - 48;
         calcBgWidth = "100%";
         borderRadius = 0;
         borderRadiusLg = "1.5rem";
      } else if (window.matchMedia('(max-width: 1279px)').matches) {
         calcBgWidthLg = maxWidth - 48;
         borderRadiusLg = "1.5rem";
      } else if (window.matchMedia('(max-width: 1599px)').matches) {
         calcBgWidthLg = maxWidth - 256;
      } else {
         calcBgWidthLg = maxWidth - 384;
      }
   }
   adjustWidth();
   
   const innerBgLg = document.querySelectorAll('.bg-inner-lg');
   if (innerBgLg.length > 0) {
      innerBgLg.forEach(bg => {
         gsap.fromTo(bg, {
            width: "100%",
            height: "100%",
            borderRadius: "0",
         }, {
            width: calcBgWidthLg,
            height: "calc(100% - 6rem)",
            borderRadius: borderRadiusLg,
            ease: "power2.out",
            duration: 1,
            scrollTrigger: {
               trigger: bg,
               start: "top 50%",
               toggleActions: "play none none reverse"
            }
         })
      })
   }
   
   const innnerBg = document.querySelectorAll('.bg-inner');
   if (innnerBg.length > 0) {
      innnerBg.forEach(bg => {
         gsap.fromTo(bg, {
            width: "100%",
            borderRadius: "0",
         }, {
            width: calcBgWidth,
            borderRadius: borderRadius,
            ease: "power2.out",
            duration: 1,
            scrollTrigger: {
               trigger: bg,
               start: "top 60%",
               toggleActions: "play none none reverse"
            }
         })
      })
   }
}

backgroundAnimation();

// Feature Images
const featureImageLeft = document.querySelector('.feature-image-left');
const featureImageRight = document.querySelector('.feature-image-left');

if (featureImageLeft) {
   gsap.from(".feature-image-left", {
      y: -100,
      scale: "0.5",
      ease: "slow",
      duration: 1,
      delay: 0.5,
      scrollTrigger: {
         trigger: ".bg-inner-lg",
         start: "top 50%",
         toggleActions: "play none none reverse"
      }
   })
}

if (featureImageRight) {
   gsap.from(".feature-image-right", {
      x: "3rem",
      ease: "slow",
      duration: 1,
      scrollTrigger: {
         trigger: ".bg-inner-lg",
         start: "top 50%",
         toggleActions: "play none none reverse"
      }
   })
}

const circleProgress = document.querySelectorAll('.circle-progress');

if (circleProgress.length > 0) {
   circleProgress.forEach(circle => {
      const path = circle.querySelector('path');
      const toValue = `${circle.getAttribute('percent')*2.502},250.2`;
      gsap.fromTo(path, {
         strokeDasharray: "0,250.2",
         opacity: 0
      }, {
         strokeDasharray: toValue,
         opacity: 1,
         duration: 1,
         ease: "power2.out",
         scrollTrigger: {
            trigger: circle,
            toggleActions: "play none none reverse",
            start: "top 60%"
         }
      })

   })
}
const animatesLines = document.querySelectorAll('.animated-lines');

if (animatesLines.length > 0) {
   animatesLines.forEach(innerLine => {
      const line = innerLine.querySelectorAll('.line');
      
      gsap.from(line, {
         height: 0,
         ease: "power2.out",
         stagger: 0.1,
         duration: 0.1,
         scrollTrigger: {
            trigger: line,
            toggleActions: "play none none reverse",
            start: "top 80%"
         }
      })

   })
}

// Logo Animation
const scrollWrapper = document.querySelector('.scroll-wrapper');
if (scrollWrapper) {
   const totalWidth = scrollWrapper.scrollWidth / 2;

   const animation = gsap.fromTo(
      scrollWrapper,
      { x: 0 },
      {
         x: -totalWidth,
         duration: 20,
         ease: 'none',
         repeat: -1
      }
   );

   // Pause animation on mouse hover and resume when the cursor leaves
   scrollWrapper.addEventListener('mouseenter', () => {
      animation.pause();
   });
   scrollWrapper.addEventListener('mouseleave', () => {
      animation.play();
   });
}