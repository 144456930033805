import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

var stats = new Swiper(".stats", {
	slidesPerView: "1.85",
	spaceBetween: 24,
	loop: true,
	centeredSlides: true,
	autoplay: {
		enabled: true,
		delay: 600000,
	},
	breakpoints: {
		768: {
			slidesPerView: 4,
			spaceBetween: 0,
			loop: false,
			centeredSlides: false,
			autoplay: {
				enabled: false
			}
		},
		1280: {
			slidesPerView: 4,
			spaceBetween: 96,
			loop: false,
			centeredSlides: false,
			autoplay: {
				enabled: false
			}
		}
	}
});

const homeCarousel = new Swiper(".home-carousel", {
	slidesPerView: "1.25",
	spaceBetween: 24,
	slidesOffsetBefore: 24,
	slidesOffsetAfter: 24,
	navigation: {
		prevEl: '.home-prev',
		nextEl: '.home-next'
	},
	breakpoints: {
		768: {
			slidesPerView: "auto",
			spaceBetween: 32,
			slidesOffsetBefore: 48,
			slidesOffsetAfter: 48,
		},
		1280: {
			slidesPerView: "auto",
			spaceBetween: 32,
			slidesOffsetBefore: 96,
			slidesOffsetAfter: 96,
		},
		1600: {
			slidesPerView: "auto",
			spaceBetween: 32,
			slidesOffsetBefore: 128,
			slidesOffsetAfter: 128,
		}
	}
})

var thumbs = new Swiper(".thumbnail", {
	spaceBetween: 10,
	slidesPerView: "4.25",
	watchSlidesProgress: true,
});
	 
var gallery = new Swiper(".gallery", {
	spaceBetween: 16,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
	thumbs: {
		swiper: thumbs,
	},
});